
.ks-footer-ui {
  height: 326px;
  color: #a0a0a0;
  min-width: 1200px;
}
.ks-footer-ui .footer-box {
    width: 1200px;
    margin: 0 auto;
}
.ks-footer-ui .footer-box .nav-box {
      padding: 30px 0;
      font-size: 18px;
      border-bottom: 1px solid #dcdcdc;
}
.ks-footer-ui .footer-box .nav-box li {
        float: left;
        width: 200px;
        text-align: center;
        cursor: pointer;
}
.ks-footer-ui .footer-box .contact-box {
      font-size: 40px;
      padding: 40px 0;
}
.ks-footer-ui .footer-box .contact-box li {
        height: 70px;
        line-height: 70px;
        width: 495px;
}
.ks-footer-ui .footer-box .contact-box li i {
          margin-right: 25px;
}
.ks-footer-ui .footer-box .contact-box .tr {
        text-align: right;
}
.ks-footer-ui .footer-box .footer-tx {
      text-align: center;
      font-size: 18px;
      margin-top: 10px;
}
.ks-footer-ui .footer-box .footer-tx a {
        color: #a0a0a0;
        text-decoration: none;
}
.ks-footer-ui .footer-box .footer-tx span {
        cursor: pointer;
        margin-left: 10px;
}
