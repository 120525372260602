
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td, article, section {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}
input {
  -webkit-appearance: none;
  border-radius: 0;
  font-size: 0.15rem;
  color: #333333;
  font-weight: 400;
  border: 0;
  padding: 0;
}
input::-webkit-input-placeholder {
  color: #bebdbd;
}
input::-moz-placeholder {
  color: #bebdbd;
}
input:-ms-input-placeholder {
  color: #999;
}
textarea::-webkit-input-placeholder {
  color: #bebdbd;
}
textarea::-moz-placeholder {
  color: #bebdbd;
}
textarea:-ms-input-placeholder {
  color: #bebdbd;
}
textarea {
  -webkit-appearance: none;
  resize: none;
  outline: none;
  font-size: 0.15rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
body {
  line-height: 1;
  font-size: 0.15rem;
  -webkit-text-size-adjust: none;
  color: #333333;
}
div, article, section, span, p, ul, li, input, footer {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
ol, ul, li {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

/* remember to highlight inserts somehow! */
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.fr {
  float: right;
}
.fl {
  float: left;
}
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
.clearfix {
  zoom: 1;
}

/* down-am */
.fade-enter-active, .fade-leave-active {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.more-click {
  text-align: center;
  font-size: 20px;
  border-top: 1px solid #a0a0a0;
  width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.more-click span {
    display: inline-block;
    width: 200px;
    padding: 10px;
    background: #efefef;
    cursor: pointer;
}
.icon-tm {
  background: url("img/icon-tm.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-gl {
  background: url("img/icon-gl.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-hg {
  background: url("img/icon-hg.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-gw {
  background: url("img/icon-gw.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-pz {
  background: url("img/icon-pz.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-zx {
  background: url("img/iocn-zx.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-phone {
  background: url("img/icon-phone.png");
  width: 70px;
  height: 70px;
  display: inline-block;
  background-size: cover;
}
.icon-email {
  background: url("img/icon-email.png");
  width: 70px;
  height: 70px;
  display: inline-block;
  background-size: cover;
}
.icon-jt {
  background: url("img/icon-jt.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-ll {
  background: url("img/icon-ll.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-cs {
  background: url("img/icon-cs.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-gll {
  background: url("img/icon-gll.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-jy {
  background: url("img/icon-jy.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-ls {
  background: url("img/icon-ls.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-ai {
  background: url("img/icon-ai.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-ai1 {
  background: url("img/icon-ai1.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-phone1 {
  background: url("img/icon-phone1.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-email1 {
  background: url("img/icon-email1.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
.icon-add {
  background: url("img/icon-add.png");
  width: 130px;
  height: 130px;
  display: inline-block;
  background-size: cover;
}
