
.ks-header-ui {
  height: 90px;
  line-height: 90px;
  font-size: 18px;
  width: 1200px;
  margin: 0 auto;
}
.ks-header-ui img {
    vertical-align: middle;
    cursor: pointer;
}
.ks-header-ui .nav-box {
    float: right;
}
.ks-header-ui .nav-box .cur {
      color: #081c33;
      font-weight: 500;
}
.ks-header-ui .nav-box li {
      cursor: pointer;
      float: left;
      margin-right: 48px;
      position: relative;
}
.ks-header-ui .nav-box li i {
        position: absolute;
        display: block;
        width: 34px;
        left: 50%;
        margin-left: -17px;
        height: 3px;
        background: #081c33;
        bottom: 20px;
}
.ks-header-ui .nav-box li:last-child {
      margin-right: 0;
}
